const diffTagEnum = {
    'insert': 'insert',
    'insert_block': 'insert',
    'insertSign': 'insert',
    'addPage': 'insert',
    'replace': 'replace',
    'modify_block': 'replace',
    'delete': 'delete',
    'remove_block': 'delete',
    'missPage': 'delete',
}

export {
    diffTagEnum
}