<template>
  <div class="comparison-page">
    <div class="head-box">
      <div class="head-item">
        <el-upload
            action="#"
            class="upload-demo"
            :on-remove="handleRemove1"
            :on-change="handleChange1"
            :limit="1"
            :auto-upload="false"
            :file-list="files.file1">
          <el-button size="small" type="primary" :disabled="!!files.file1.length">上传标准文档</el-button>
        </el-upload>
      </div>

      <div class="head-item">
        <el-upload
            action="#"
            class="upload-demo"
            :on-remove="handleRemove2"
            :on-change="handleChange2"
            :limit="1"
            :auto-upload="false"
            :file-list="files.file2">
          <el-button size="small" type="primary" :disabled="!!files.file2.length">上传比对文档</el-button>
        </el-upload>
      </div>

      <div class="head-item">
        <el-button type="primary" size="small" @click="handleComparison" :loading="diffLoading">开始比对</el-button>
      </div>
      <div class="head-item">
        <el-button size="small" @click="resetDoc">重置</el-button>
      </div>
    </div>

    <div class="main-box" v-loading="mainLoading">
      <div class="main-left">
        <div class="thumbnail-item" :class="{'active-thumbnail-item': index === currentIndex}" v-for="(item, index) in file1List" :key="index" @click="gotoFilePage(index)">
          <span class="thumbnail-item-index">{{index + 1}}</span>
          <img :src="baseURL + item" />
        </div>
      </div>

      <div class="main-content">
        <div class="dashed-line-box"></div>
        <div class="file-left-box file-box">
          <div class="file-left-head file-head">
            <h4>标准文档</h4>
            <span>{{fileNames[0] || ''}}</span>
          </div>
          <div class="file-left-content file-content" ref="fileLeftContentDom">
            <div class="file-content-box" ref="fileLeftContentBox"
                 @mousedown="fileMousedown" @mousemove.prevent="fileMousemove"
                 @mouseup="fileMouseup"
            >
              <div class="file-content-item" v-for="(item, index) in file1List" :key="index"
                   :style="{'height': fileItemHeight + 'px'}">
                <span v-for="(ele, idx) in getSpans('left', index)" :style="getSpanStyle(ele)" :key="idx"
                      :class="{
                        'insert-animation-span': currentDiffKey === ele.key && currentDiffType === ele.tag && diffTagEnum[ele.tag] === 'insert',
                        'replace-animation-span': currentDiffKey === ele.key && currentDiffType === ele.tag && diffTagEnum[ele.tag] === 'replace',
                        'delete-animation-span': currentDiffKey === ele.key && currentDiffType === ele.tag && diffTagEnum[ele.tag] === 'delete',
                      }"
                      @click="handleDiff(ele)"
                ></span>
                <img :src="baseURL + item" />
              </div>
            </div>
          </div>
          <div class="file-left-foot file-foot"></div>
        </div>

        <div class="file-right-box file-box">
          <div class="file-right-head file-head">
            <h4>比对文档</h4>
            <span>{{fileNames[1] || ''}}</span>
          </div>
          <div class="file-right-content file-content" ref="fileRightContentDom">
            <div class="file-content-box" ref="fileRightContentBox"
                 @mousedown="fileMousedown" @mousemove.prevent="fileMousemove"
                 @mouseup="fileMouseup"
            >
              <div class="file-content-item" v-for="(item, index) in file2List" :key="index"
                   :style="{'height': fileItemHeight + 'px'}">
                <span v-for="(ele, idx) in getSpans('right', index)" :style="getSpanStyle(ele)" :key="idx"
                      :class="{
                        'insert-animation-span': currentDiffKey === ele.key && currentDiffType === ele.tag && diffTagEnum[ele.tag] === 'insert',
                        'replace-animation-span': currentDiffKey === ele.key && currentDiffType === ele.tag && diffTagEnum[ele.tag] === 'replace',
                        'delete-animation-span': currentDiffKey === ele.key && currentDiffType === ele.tag && diffTagEnum[ele.tag] === 'delete',
                      }"
                      @click="handleDiff(ele)"
                ></span>
                <img :src="baseURL + item" />
              </div>
            </div>
          </div>
          <div class="file-right-foot file-foot"></div>
        </div>
      </div>

      <div class="main-right">
        <div class="main-right-tabs">
          <el-radio-group v-model="diffType" @input="diffTypeChange"
                          size="small">
            <el-radio-button label="all">全部({{diffListNumber.all}})</el-radio-button>
            <el-radio-button label="insert">新增({{diffListNumber.insert}})</el-radio-button>
            <el-radio-button label="replace">修改({{diffListNumber.replace}})</el-radio-button>
            <el-radio-button label="delete">删除({{diffListNumber.delete}})</el-radio-button>
          </el-radio-group>

          <el-radio-group v-model="diffLevel" @input="diffLevelChange"
                          size="small">
            <!--            <el-radio-button label="all">全部({{diffLevelNumber.all}})</el-radio-button>-->
            <el-radio-button :label="2">高({{diffLevelNumber.senior}})</el-radio-button>
<!--            <el-radio-button :label="1">中({{diffLevelNumber.intermediate}})</el-radio-button>-->
            <el-radio-button :label="0">低({{diffLevelNumber.ordinary}})</el-radio-button>
          </el-radio-group>
        </div>
        <div class="main-right-list" ref="mainRightList">
          <template v-if="diffListInfo && diffListInfo.length">
            <diffList v-for="(item, index) in diffListInfo" :key="index" :item="item" :index="index" @handleItem="handleItem"
                      :style="{'background-color': index === currentDiffIndex ? '#f1f1f1' : ''}"
                      :class="{
                        'insert-selected-item': index === currentDiffIndex && diffTagEnum[currentDiffType] === 'insert',
                        'replace-selected-item': index === currentDiffIndex && diffTagEnum[currentDiffType] === 'replace',
                        'delete-selected-item': index === currentDiffIndex && diffTagEnum[currentDiffType] === 'delete',
                      }"
                      ref="diffListRef"
            ></diffList>
          </template>
          <el-empty v-else description="暂无数据"></el-empty>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { request, baseURL } from '@/api/request'
import { diffTagEnum } from '@/common/config'

import diffList from "@/components/diffList.vue";

export default {
  name: 'ComparisonPage',
  components: { diffList },
  data() {
    return {
      diffLoading: false,
      mainLoading: false,
      // 渲染数据
      files: {
        file1: [],
        file2: [],
      },
      fileNames: [],
      // 所有差异列表
      diffAll: [],
      diffListInfoAll: [],
      diffListInfoAllMerge: [],
      // 右侧列表
      diffListInfo: [],
      file1List: [],
      file2List: [],
      diffType: 'all',
      diffLevel: 2,
      // 功能
      currentDiffIndex: null,
      currentIndex: 0,
      currentScrollTop: 0,
      currentDiffKey: '',
      currentDiffType: '',
      imgDefaultWidth: 1191,
      imgDefaultHeight: 1685,
      fileItemHeight: 1020,
      spaceDown: false,
      mouseDown: false,
      mouseStartPosition: {
        x: 0,
        y: 0
      }
    }
  },
  computed: {
    baseURL () {
      return baseURL
    },
    diffListNumber () {
      const res = {}
      const temp = this.diffListInfoAll
      res.all = temp.length
      res.insert = temp.filter(item => diffTagEnum[item.tag] === 'insert').length
      res.replace = temp.filter(item => diffTagEnum[item.tag] === 'replace').length
      res.delete = temp.filter(item => diffTagEnum[item.tag] === 'delete').length
      return res
    },
    diffLevelNumber () {
      const res = {}
      let temp = []
      // 如果是全部，则取全部，否则过滤
      if(this.diffType === 'all') {
        temp = this.getTypeDiffList('all')
      } else {
        switch (this.diffType) {
          case 'insert':
            temp = this.getTypeDiffList('insert')
            break
          case 'replace':
            temp = this.getTypeDiffList('replace')
            break
          case 'delete':
            temp = this.getTypeDiffList('delete')
            break
          default:
            break
        }
      }

      res.all = temp.length
      res.senior = temp.filter(item => item.level === 2).length
      res.intermediate = temp.filter(item => item.level === 1).length
      res.ordinary = temp.filter(item => item.level === 0).length
      return res
    },
    fileItemRatio () {
      const height = this.fileItemHeight
      const width = (height * this.imgDefaultWidth) / this.imgDefaultHeight
      return {
        width: width / this.imgDefaultWidth,
        height: height / this.imgDefaultHeight
      }
    },
    diffTagEnum () {
      return diffTagEnum
    }
  },
  mounted() {
    const fileLeftContentDomWidth = this.$refs.fileLeftContentBox.offsetWidth || 0
    this.fileItemHeight = (fileLeftContentDomWidth * this.imgDefaultHeight) / this.imgDefaultWidth
    // 监听滚动
    this.$refs.fileLeftContentDom.addEventListener('scroll', this.handleLeftScroll);
    this.$refs.fileRightContentDom.addEventListener('scroll', this.handleRightScroll);

    // 监听键盘事件
    window.addEventListener('keydown', this.handleKeydown)
    window.addEventListener('keyup', this.handleKeyup)

    // 监听窗口尺寸变化
    window.addEventListener('resize', this.windowResize)
  },
  beforeDestroy() {
    // 移除滚动事件监听器
    this.$refs.fileLeftContentDom.removeEventListener('scroll', this.handleLeftScroll);
    this.$refs.fileRightContentDom.removeEventListener('scroll', this.handleRightScroll);

    window.removeEventListener('keydown', this.handleKeydown)
    window.removeEventListener('keyup', this.handleKeyup)
    window.removeEventListener('resize', this.windowResize)
  },
  methods: {
    // 左右文档同步滚动
    handleLeftScroll() {
      const scrollTop = this.$refs.fileLeftContentDom.scrollTop;
      this.currentScrollTop = scrollTop
      // 动态设置左侧缩略图active
      this.currentIndex = Math.round(scrollTop / this.fileItemHeight)
      // 设置右侧的滚动距离
      this.$refs.fileRightContentDom.scrollTop = scrollTop;
    },
    handleRightScroll() {
      const scrollTop = this.$refs.fileRightContentDom.scrollTop;
      // 设置左侧的滚动距离
      this.$refs.fileLeftContentDom.scrollTop = scrollTop;
    },

    // 键盘按下
    handleKeydown (ev) {
      if(ev.code === 'Space') {
        ev.preventDefault()
        this.spaceDown = true
        this.$refs.fileLeftContentDom.style.cursor = 'move'
        this.$refs.fileRightContentDom.style.cursor = 'move'
      }
    },
    // 键盘弹起事件
    handleKeyup () {
      this.spaceDown = false
      this.$refs.fileLeftContentDom.style.cursor = 'default'
      this.$refs.fileRightContentDom.style.cursor = 'default'
    },

    // 鼠标按下事件
    fileMousedown (ev) {
      if (this.spaceDown) {
        this.mouseDown = true
        this.mouseStartPosition.x = ev.clientX
        this.mouseStartPosition.y = ev.clientY
      }
    },
    // 鼠标弹起事件
    fileMouseup () {
      this.mouseDown = false
    },
    // 鼠标移动事件
    fileMousemove (ev) {
      if (this.spaceDown && this.mouseDown) {
        // 获取当前偏移量
        // const x = this.$refs.fileLeftContentBox.offsetLeft
        // const y = this.$refs.fileLeftContentBox.offsetTop

        const cx = ev.clientX - this.mouseStartPosition.x
        this.$refs.fileLeftContentBox.style.left = cx + 'px'
        this.$refs.fileRightContentBox.style.left = cx + 'px'
        const cy = ev.clientY - this.mouseStartPosition.y
        this.$refs.fileLeftContentBox.style.top = cy + 'px'
        this.$refs.fileRightContentBox.style.top = cy + 'px'
      }
    },

    // 窗口缩放
    windowResize () {
      const fileLeftContentDomWidth = this.$refs.fileLeftContentBox.offsetWidth || 0
      this.fileItemHeight = (fileLeftContentDomWidth * this.imgDefaultHeight) / this.imgDefaultWidth
    },

    // 文件上传
    handleChange1 (res) {
      // 文件格式校验
      const {name} = res
      const reg = /\.(doc|docx|pdf|xls|xlsx)$/
      if(!reg.test(name)) {
        this.files.file1.splice(0)
        this.$message.warning('附件格式不正确，请上传格式为 .doc/.docx/.pdf/.xls/.xlsx 的文件')
        return
      }
      // 上传文件大小限制
      const sizeMb = res.raw.size / 1024 / 1024
      if(sizeMb > 50) {
        this.files.file1.splice(0)
        this.$message.warning('上传附件不得大于50MB，请重新上传')
        return
      }

      this.files.file1.push(res.raw)
    },
    handleRemove1 () {
      this.files.file1.splice(0)
    },
    handleChange2 (res) {
      // 文件格式校验
      const {name} = res
      const reg = /\.(doc|docx|pdf|xls|xlsx)$/
      if(!reg.test(name)) {
        this.files.file2.splice(0)
        this.$message.warning('附件格式不正确，请上传格式为 .doc/.docx/.pdf/.xls/.xlsx 的文件')
        return
      }
      // 上传文件大小限制
      const sizeMb = res.raw.size / 1024 / 1024
      if(sizeMb > 50) {
        this.files.file2.splice(0)
        this.$message.warning('上传附件不得大于50MB，请重新上传')
        return
      }

      this.files.file2.push(res.raw)
    },
    handleRemove2 () {
      this.files.file2.splice(0)
    },

    // 重置
    reset () {
      this.diffType = 'all'
      this.diffLevel = 2
      this.currentDiffKey = ''
      this.currentDiffType = ''
      this.currentScrollTop = 0
    },
    // 重置文档
    resetDoc () {
      this.reset()
      this.files.file1.splice(0)
      this.files.file2.splice(0)

      this.diffAll.splice(0)
      this.diffListInfoAll.splice(0)
      this.diffListInfo.splice(0)

      this.file1List.splice(0)
      this.file2List.splice(0)
      this.fileNames.splice(0)
    },

    // 格式化area
    formatArea (ele) {
      const { area } = ele
      let res = []
      // 处理area为空的情况
      if(!area || (area && !area.length && !area[0] && !area[1])) {
        res[0] = [ [0, 0], [0, 0], [0, 0], [0, 0] ]
        res[1] = [ [0, 0], [0, 0], [0, 0], [0, 0] ]
      } else {
        res = [ area[0], area[1] ]
      }

      // const handleEmptyStatus = (arr) => {
      //   const top = arr[0][1]
      //   const bottom = arr[2][1]
      //   const left = arr[0][0]
      //   const right = arr[0][0]
      //   return [
      //     [left, top],
      //     [right, top],
      //     [left, bottom],
      //     [right, bottom],
      //   ]
      // }
      if(area && area.length && !area[0].length) {
        // res[0] = handleEmptyStatus(area[1])
        res[0] = [ [0, 0], [0, 0], [0, 0], [0, 0] ]
      }
      if(area && area.length && !area[1].length) {
        // res[1] = handleEmptyStatus(area[0])
        res[1] = [ [0, 0], [0, 0], [0, 0], [0, 0] ]
      }

      return res
    },

    // 开始对比
    async handleComparison () {
      // 暂时注释 TODO
      if(!this.files.file1.length || !this.files.file2.length) {
        this.$message.warning('请先选择比对文件')
        return
      }
      // 重置
      this.reset()
      this.diffLoading = true
      this.mainLoading = true

      try {
        // 上传文件 暂时注释 TODO
        const form = new FormData();
        form.append('file[]', this.files.file1[0]);
        form.append('file[]', this.files.file2[0]);
        const uploadRes = await request('post', '/upload', form)
        if (!uploadRes.data.length) {
          this.$message.warning('文档解析失败，请重试')
          this.diffLoading = false
          this.mainLoading = false
          return
        }

        // 获取差异
        // const diffForm = {"path1":"files\\uploads\\202404261746244685.pdf","path2":"files\\uploads\\202404261746247046.pdf"}
        const diffForm = {
          "path1": uploadRes.data[0],
          "path2": uploadRes.data[1]
        }
        const diffRes = await request('post', '/ocr/compare/contract', diffForm)
        const {data, imgs1, imgs2} = diffRes

        // 所有的差异列表
        this.diffAll = data.filter(item => (item.Details && item.Details.length) || item.Tag === 'insertSign')

        this.diffListInfoAll.splice(0)
        // 格式化
        this.diffAll.forEach((item, index) => {
          let tempDetails = JSON.parse(JSON.stringify(item.Details))

          // 组装格式传入diffList组件
          if(!tempDetails.length && item.Tag === 'insertSign') {
            tempDetails = [{
              level: 2,
              pos: [],
              Page: item.Page - 1,
              pIndex: index,
              tag: item.Tag,
              key: Date.now() + '' + Math.round(Math.random() * 10000),
            }]
          } else {
            tempDetails.forEach(ele => {
              // 这里页码 -1 保持同步
              ele.Page = item.Page - 1
              ele.str1 = item.str1
              ele.str2 = item.str2

              ele.pIndex = index
              ele.key = Date.now() + '' + Math.round(Math.random() * 10000)
              ele.area = this.formatArea(ele)
            })
          }
          this.diffListInfoAll.push(...tempDetails)
        })

        this.diffListInfoAll.sort((a, b) => b.level - a.level)
        // 默认全部 差异等级为2的

        this.diffListInfo.splice(0)
        this.diffListInfo.push(...this.getTypeDiffList('all', 2))

        // 图片
        this.file1List.splice(0)
        this.file1List.push(...imgs1)
        this.file2List.splice(0)
        this.file2List.push(...imgs2)

        // 获取图片原始大小，实现自适应
        const img = new Image();
        const that = this
        img.onload = function() {
          that.imgDefaultWidth = this.width;
          that.imgDefaultHeight = this.height;
        };
        img.src = baseURL + imgs1[0];

        // 渲染文档名称
        this.fileNames.splice(0)
        const {file1, file2} = this.files
        this.fileNames.push(file1[0].name)
        this.fileNames.push(file2[0].name)

        this.diffLoading = false
        this.mainLoading = false
      } catch (err) {
        console.log('出错', err)
        this.diffLoading = false
        this.mainLoading = false
      }
    },

    // 获取每页的差异值
    getSpans (type, index) {
      const filterArr = this.diffListInfoAll.filter(item => item.Page === index)
      return filterArr.map(item => {
        return {
          level: item.level,
          key: item.key,
          tag: item.tag,
          area: item.area ? item.area[type === 'left' ? 0 : 1] : null
        }
      })
    },

    // 动态设置span的样式
    getSpanStyle (item) {
      const {level, tag, area} = item
      if(!area) return {}
      // 获取宽高
      const width = (area[1][0] - area[0][0]) * this.fileItemRatio.width + 'px'
      const height = (area[3][1] - area[0][1]) * this.fileItemRatio.height + 'px'
      // 获取偏移量
      const left = area[0][0] * this.fileItemRatio.width + 'px'
      const top = area[0][1] * this.fileItemRatio.height + 'px'

      // 根据不同类型来设置不同颜色
      const getTypeColor = (level, tag) => {
        // 等级不为2时将不主动渲染颜色在页面中
        if(level !== 2) return {}
        const tempTag = diffTagEnum[tag]
        switch (tempTag) {
          case 'insert':
            return {
              'background-color': '#1bcdca',
              'border': '1px solid #134b4a'
            }
          case 'replace':
            return {
              'background-color': '#ffbd1a',
              'border': '1px solid #5e4709'
            }
          case 'delete':
            return {
              'background-color': '#ef6463',
              'border': '1px solid #5b0b0b'
            }
          default:
            return {}
        }
      }

      return {
        'width': width,
        'height': height,
        'left': left,
        'top': top,
        ...getTypeColor(level, tag)
      }
    },

    // 差异span点击
    handleDiff (spanItem) {
      console.log('spanItem', spanItem)
      // 重置筛选
      this.diffType = 'all'
      this.diffLevel = spanItem.level
      this.diffTypeChange('all', false)
      this.diffLevelChange(spanItem.level)
      // 配置状态
      this.currentDiffKey = spanItem.key
      this.currentDiffType = spanItem.tag

      setTimeout(() => {
        // 获取相应的 dom
        let index = 0
        for(let i = 0; i < this.$refs.diffListRef.length; i++) {
          const ele = this.$refs.diffListRef[i]
          if(ele.item.key === spanItem.key) {
            index = i
            break
          }
        }
        // 获取dom的top
        const top = this.$refs.diffListRef[index].$refs.diffListItem.offsetTop
        this.$refs.mainRightList.scrollTop = top - 200

        this.currentDiffIndex = this.$refs.diffListRef[index].index
      }, 100)
    },

    // 根据状态筛选差异列表
    getTypeDiffList (type, level) {
      const arr = []
      switch (type) {
        case 'all':
          arr.push(...this.diffListInfoAll.map(item => item))
          break
        case 'insert':
          arr.push(...this.diffListInfoAll.filter(item => diffTagEnum[item.tag] === 'insert'))
          break
        case 'replace':
          arr.push(...this.diffListInfoAll.filter(item => diffTagEnum[item.tag] === 'replace'))
          break
        case 'delete':
          arr.push(...this.diffListInfoAll.filter(item => diffTagEnum[item.tag] === 'delete'))
          break
        default:
          break
      }
      if(level !== undefined) {
        return arr.filter(item => item.level === level)
      } else {
        return arr
      }
    },

    // 差异类型切换
    diffTypeChange (res, setDiffLevel = true) {
      if(setDiffLevel) {
        this.diffLevel = 2
      }
      this.currentDiffIndex = null
      this.diffListInfo.splice(0)
      if(res === 'all') {
        this.diffListInfo.push(...this.getTypeDiffList('all', 2))
      } else {
        this.diffListInfo.push(...this.getTypeDiffList(res, 2))
      }
    },
    // 差异等级切换
    diffLevelChange (res) {
      this.currentDiffIndex = null
      this.diffListInfo.splice(0)
      if(res === 'all') {
        // 如果是全部，则根据差异类型来返回所有
        if(this.diffType === 'all') {
          this.diffListInfo.push(...this.diffListInfoAll.map(item => item))
        } else {
          this.diffListInfo.push(...this.diffListInfoAll.filter(item => item.tag === this.diffType))
        }
      } else {
        // 其他等级的话则需要根据当前差异类型来过滤
        if(this.diffType === 'all') {
          // 先获取全部的，然后过滤
          this.diffListInfo.push(...this.getTypeDiffList('all', res))
        } else {
          // 先获取对应差异，再过滤
          this.diffListInfo.push(...this.getTypeDiffList(this.diffType, res))
        }
      }
    },

    // 点击差异
    handleItem (res, idx) {
      console.log('res', res)
      console.log('ppp res', this.diffAll[res.pIndex])
      this.currentDiffIndex = idx
      this.currentDiffKey = res.key
      this.currentDiffType = res.tag
      const index = res.Page || 0
      // 获取当前位置
      const fileDomHeight = this.$refs.fileLeftContentDom.offsetHeight
      const positionTop = res.area ? res.area[0] ? res.area[0][0][1] : 0 : 0
      const top = (index * this.fileItemHeight) + (positionTop * this.fileItemRatio.height) - (fileDomHeight / 2)
      if(top < 0) {
        this.gotoFilePage(index)
      } else {
        this.gotoScrollTop(top)
      }
    },
    // 跳转到指定页
    gotoFilePage(index) {
      this.currentIndex = index
      this.$refs.fileLeftContentDom.scrollTop = index * this.fileItemHeight
      this.$refs.fileRightContentDom.scrollTop = index * this.fileItemHeight
    },
    // 滚动到指定距离
    gotoScrollTop (top) {
      this.$refs.fileLeftContentDom.scrollTop = top
      this.$refs.fileRightContentDom.scrollTop = top
    }
  }
}
</script>

<style scoped>
.comparison-page {
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
}
.head-box {
  box-sizing: border-box;
  padding: 0 20px;
  width: 100vw;
  height: 50px;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.head-item {
  margin-left: 10px;
}
.main-box {
  background-color: #f7f7fb;
  display: flex;
  width: 100vw;
  height: calc(100% - 50px);
  box-sizing: border-box;
}
.main-left {
  width: 97px;
  height: 100%;
  overflow-y: overlay;
  background-color: #fff;
  border-right: 1px solid #ddd;
  box-sizing: border-box;
  padding: 20px 12px;
}
.main-left::-webkit-scrollbar {
  width: 8px;
  border-radius: 4px;
}
.main-left::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #ddd;
}
.active-thumbnail-item {
  border: 1px solid #4877ff !important;
}
.active-thumbnail-item .thumbnail-item-index {
  color: #fff !important;
  background-color: #4877ff !important;
}
.thumbnail-item {
  width: 100%;
  height: 100px;
  border: 1px solid #ddd;
  margin-bottom: 6px;
  border-radius: 2px;
  box-sizing: border-box;
  position: relative;
}
.thumbnail-item .thumbnail-item-index {
  position: absolute;
  left: 0;
  top: 0;
  width: 16px;
  height: 16px;
  background-color: #ddd;
  color: #333;
  text-align: center;
  line-height: 16px;
  font-size: 14px;
  border-radius: 2px;
}
.thumbnail-item img {
  width: 100%;
  height: 100%;
}
.main-content {
  flex: 1;
  display: flex;
}
.main-content .file-box {
  width: 50%;
  box-sizing: border-box;
  padding: 20px 10px 0;
}
.main-content .file-box .file-head {
  width: 100%;
  font-size: 14px;
  margin-bottom: 12px;
  height: 24px;
  line-height: 24px;
  display: flex;
}
.main-content .file-box .file-head h4 {
  min-width: 60px;
  margin-right: 15px;
}
.main-content .file-box .file-head span {
  overflow: hidden;
  text-overflow: ellipsis;
}
.main-content .file-box .file-content {
  background-color: #fff;
  height: calc(100% - 56px);
  overflow-y: overlay;
  overflow-x: hidden;
  position: relative;
}
.file-content .file-content-box {
  //width: 120%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  position: absolute;
  //left: -50px;
  left: 0;
  top: 0;
}
.file-content::-webkit-scrollbar {
  width: 8px;
  border-radius: 4px;
}
.file-content::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #ddd;
}
.file-content-item {
  width: 100%;
  border-bottom: 1px solid #ddd;
  position: relative;
}
.file-content-item img {
  width: 100%;
  height: 100%;
}
.file-content-item span {
  position: absolute;
  opacity: 0.2;
}
.file-content-item .insert-animation-span {
  background-color: #1bcdca !important;
  border: 1px solid #134b4a !important;
  animation: insertSpanByte 0.8s infinite;
}
.file-content-item .replace-animation-span {
  background-color: #ffbd1a !important;
  border: 1px solid #5e4709 !important;
  animation: replaceSpanByte 0.8s infinite;
}
.file-content-item .delete-animation-span {
  background-color: #ef6463 !important;
  border: 1px solid #5b0b0b !important;
  animation: deleteSpanByte 0.8s infinite;
}
@keyframes insertSpanByte {
  33% {
    box-shadow: 1px 1px 1px 1px #134b4a, -1px -1px 1px 1px #134b4a;
  }

  66% {
    box-shadow: 1px 1px 2px 2px #134b4a, -1px -1px 2px 2px #134b4a;
  }

  100% {
    box-shadow: 1px 1px 1px 1px #134b4a, -1px -1px 1px 1px #134b4a;
  }
}
@keyframes replaceSpanByte {
  33% {
    box-shadow: 1px 1px 1px 1px #5e4709, -1px -1px 1px 1px #5e4709;
  }

  66% {
    box-shadow: 1px 1px 2px 2px #5e4709, -1px -1px 2px 2px #5e4709;
  }

  100% {
    box-shadow: 1px 1px 1px 1px #5e4709, -1px -1px 1px 1px #5e4709;
  }
}
@keyframes deleteSpanByte {
  33% {
    box-shadow: 1px 1px 1px 1px #5b0b0b, -1px -1px 1px 1px #5b0b0b;
  }

  66% {
    box-shadow: 1px 1px 2px 2px #5b0b0b, -1px -1px 2px 2px #5b0b0b;
  }

  100% {
    box-shadow: 1px 1px 1px 1px #5b0b0b, -1px -1px 1px 1px #5b0b0b;
  }
}
.main-right {
  background-color: #fff;
  width: 340px;
  border-left: 1px solid #ddd;
}
.main-right-tabs {
  box-sizing: border-box;
  padding: 20px 20px 0 30px;
}
.main-right-list {
  width: 100%;
  overflow-y: overlay;
  height: calc(100% - 104px);
}
.main-right-list::-webkit-scrollbar {
  width: 8px;
  border-radius: 4px;
}
.main-right-list::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #ddd;
}
.main-right-list .insert-selected-item {
  background-color: #e7fafa;
}
.main-right-list .replace-selected-item {
  background-color: #fff8e7;
}
.main-right-list .delete-selected-item {
  background-color: #feefef;
}
</style>

<style>
.comparison-page .upload-demo {
  display: flex;
}

.comparison-page .upload-demo .el-upload-list {
  display: flex;
  align-items: center;
}

.comparison-page .upload-demo .el-upload-list .el-upload-list__item {
  margin-top: 0;
}

.main-right-tabs .el-radio-button__inner {
  width: 70px !important;
  height: 32px;
  line-height: 32px;
  padding: 0 10px;
}

.main-right-tabs .el-radio-group {
  margin-bottom: 10px !important;
}
</style>
