import axios from 'axios'

// const baseURL = 'http://localhost:5000/'
 const baseURL = 'https://ocr-docdiff.hofo.co'
// const baseURL = 'http://wan.mtm.ink:5000/'
//const baseURL = 'http://code.mtm.ink:5001/'
// const dev = '/api'

// 请求超时时间设置为1000毫秒（1秒）
const timeout = 1000 * 60 * 30
const instance = axios.create({
    timeout,
});

const request = (method, url, data) => {
    return new Promise((resolve, reject) => {
        // 暂时关闭代理转发 04/26
        instance[method](baseURL + url, data).then(res => {
            resolve(res.data)
        }).catch((err) => reject(err))
    })
}

export {
    request,
    baseURL
}