<template>
  <div class="diff-list-item" @click="handleItem" ref="diffListItem">
    <p class="type">
      <span class="tag">
        <el-tag size="mini" :type="diffLevel.type">{{diffLevel.text}}</el-tag>
      </span>
      <span class="position">
<!--        {{ '第 ' + (item.Page + 1) + ' 页；' + '第 ' + (item.row || 1) + ' 行' }}-->
        {{ '第 ' + (item.Page + 1) + ' 页' }}
      </span>
    </p>
    <p class="standard info"
        v-if="!['addPage', 'missPage', 'insertSign'].includes(item.tag)"
    >标准文本：<span>{{ item.str1 || '' }}</span></p>
    <p class="diff info"
       v-if="!['addPage', 'missPage', 'insertSign'].includes(item.tag)"
    >比对文本：<span>{{ item.str2 || '' }}</span></p>
    <p class="info">
      差异内容：
      <span>{{item.display || ''}}</span>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object
    },
    index: {
      type: Number
    }
  },
  data() {
    return {}
  },
  computed: {
    diffLevel () {
      const res = {
        type: '',
        text: '默认'
      }
      const num = this.item.level
      switch (num) {
        case 2:
          res.type = 'danger'
          res.text = '高'
          break
        case 1:
          res.type = ''
          res.text = '中'
          break
        case 0:
          res.type = 'info'
          res.text = '低'
          break
        default:
          break
      }
      return res
    }
  },
  methods: {
    handleItem () {
      this.$emit('handleItem', this.item, this.index)
    }
  }
}
</script>

<style scoped>
.diff-list-item {
  cursor: pointer;
  width: 100%;
  padding: 15px 20px 15px 30px;
  box-sizing: border-box;
  border-bottom: 1px solid #ddd;
  font-size: 14px;
}
.type {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-weight: bold;
}
.type .tip {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
.type .tag {
  margin-right: 0 !important;
}
.type span {
  margin-right: 10px;
}
.type .position {
  color: #666;
}
.info {
  //padding-left: 18px;
  margin-bottom: 8px;
  word-break: break-all;
}
.info span {
  color: #999;
}
</style>